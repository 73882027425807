var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable quote-props */
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { alignToCenter, ContainerColumn } from '@components/styled';
import Input from '@shared/ui/inputs/Input';
import InputPassword from '@shared/ui/inputs/InputPassword';
import { handleResponseError } from '@pages/auth/lib/utils';
import { emailRegistrationValidationSchema } from '../../../../../../model/register-email';
import { ButtonContainer, SubmitButton } from '../../../../../components/shared';
import RulesCheckbox from '../../../../../components/RulesCheckbox';
const ColumnContainer = alignToCenter(ContainerColumn);
const InputContainer = styled('div', { shouldForwardProp: propName => propName !== 'hasError' })(props => ({
    width: 'inherit',
    marginBottom: props.hasError ?
        props.theme.spacing_sizes.xs * 0.5 : props.theme.spacing_sizes.s * 5,
}));
const CheckboxContainer = styled.div(props => ({
    maxWidth: 458,
    marginBottom: props.theme.spacing_sizes.l,
}));
const Form = styled.form(() => ({
    width: 'inherit',
    maxWidth: 458,
}));
const INIT_VALUES = {
    email: '',
    password: '',
    repeatPassword: '',
    areRulesAccepted: false,
};
const EmailRegistrationForm = ({ onSubmit: submitFn, setEmail, isSigningUp, }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const { control, handleSubmit, setValue, setError, watch, formState: { errors }, } = useForm({
        defaultValues: INIT_VALUES,
        resolver: yupResolver(emailRegistrationValidationSchema(t)),
        mode: 'onBlur',
        reValidateMode: 'onChange',
    });
    const areRulesAccepted = watch('areRulesAccepted');
    const haveEmailErrors = typeof ((_a = errors.email) === null || _a === void 0 ? void 0 : _a.message) !== 'undefined';
    const havePasswordErrors = typeof ((_b = errors.password) === null || _b === void 0 ? void 0 : _b.message) !== 'undefined';
    const haveRepeatPasswordErrors = typeof ((_c = errors.repeatPassword) === null || _c === void 0 ? void 0 : _c.message) !== 'undefined';
    const onSubmit = (_a) => __awaiter(void 0, [_a], void 0, function* ({ email, password, repeatPassword, }) {
        const res = yield submitFn({
            email,
            password,
            passwordConfirmation: repeatPassword,
        });
        if (typeof res !== 'boolean') {
            handleResponseError({
                translateFn: t,
                error: res.error,
                setError,
            });
        }
        else {
            setEmail(email);
        }
    });
    const handleCheckboxChange = (_, value) => {
        setValue('areRulesAccepted', value);
    };
    const handleCheckboxClick = (value) => {
        setValue('areRulesAccepted', value);
    };
    const download = (ev) => {
        ev.preventDefault();
        // TODO: implement downloading logic
    };
    return (_jsx(Form, { onSubmit: handleSubmit(onSubmit), children: _jsxs(ColumnContainer, { children: [_jsx(InputContainer, { hasError: haveEmailErrors, children: _jsx(Controller, { name: 'email', control: control, render: ({ field, fieldState }) => {
                            var _a, _b;
                            return (_jsx(Input, { "data-testid": 'email_email-registration', InputLabelProps: { shrink: true }, type: 'email', autoComplete: 'email', size: 'medium', ref: field.ref, value: field.value, onChange: field.onChange, onBlur: field.onBlur, label: t('auth.sign_up.steps.credentials.email.email_input_title', { ns: 'common' }), placeholder: t('auth.sign_up.steps.credentials.email.email_input_placeholder', { ns: 'common' }), notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, disabled: isSigningUp }));
                        } }) }), _jsx(InputContainer, { hasError: havePasswordErrors, children: _jsx(Controller, { name: 'password', control: control, render: ({ field, fieldState }) => {
                            var _a, _b;
                            return (_jsx(_Fragment, { children: _jsx(InputPassword, { "data-testid": 'password_email-registration', InputLabelProps: { shrink: true }, variant: 'outlined', autoComplete: 'password', ref: field.ref, value: field.value, onChange: field.onChange, onBlur: field.onBlur, label: t('auth.sign_up.steps.credentials.email.password_input_title', { ns: 'common' }), placeholder: t('auth.sign_up.steps.credentials.email.password_input_placeholder', { ns: 'common' }), notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, disabled: isSigningUp }) }));
                        } }) }), _jsx(InputContainer, { hasError: haveRepeatPasswordErrors, children: _jsx(Controller, { name: 'repeatPassword', control: control, render: ({ field, fieldState }) => {
                            var _a, _b;
                            return (_jsx(InputPassword, { "data-testid": 'repeat-password_email-registration', InputLabelProps: { shrink: true }, variant: 'outlined', autoComplete: 'repeat-password', ref: field.ref, value: field.value, onChange: field.onChange, onBlur: field.onBlur, label: t('auth.sign_up.steps.credentials.email.repeat_password_input_title', { ns: 'common' }), placeholder: t('auth.sign_up.steps.credentials.email.repeat_password_input_placeholder', { ns: 'common' }), notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, disabled: isSigningUp }));
                        } }) }), _jsx(CheckboxContainer, { children: _jsx(RulesCheckbox, { checked: areRulesAccepted, onChange: handleCheckboxChange, onCheckboxClick: handleCheckboxClick, onTermsUsageClick: download, onPrivacyPolicyClick: download, disabled: isSigningUp }) }), _jsx(ButtonContainer, { children: _jsx(SubmitButton, { "data-testid": 'submit_email-registration', type: 'submit', size: 'large', isLoading: isSigningUp, disabled: isSigningUp || areRulesAccepted === false, fullWidth: true, children: t('auth.sign_up.steps.credentials.email.submit_btn_text', { ns: 'common' }) }) })] }) }));
};
export default EmailRegistrationForm;
